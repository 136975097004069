@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/icons.scss";
@import "../common/02-generic/button.scss";
@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/02-generic/image.scss";

$msv-checkout-margin-bottom: 28px;
$msv-checkout-margin-top: 28px;
$msv-checkout-margin-left-right: auto;
$msv-checkout-plain-container-padding-left: 0;
$msv-checkout-main-max-width: calc(100% - 400px);
$msv-checkout-side-width: 400px;
$msv-checkout-has-express-side-margin-top: -250px;
$msv-checkout-main-panel-padding-right: 64px;
$msv-checkout-guided-form-margin-bottom: 20px;
$msv-checkout-guided-card-padding-top: 32px;
$msv-checkout-guided-card-padding-bottom: 32px;
$msv-checkout-guided-card-border-top-width: 1px;
$msv-checkout-guided-card-body-margin-top: 32px;
$msv-checkout-guided-card-footer-margin-top: 40px;
$msv-checkout-guided-card-footer-margin-bottom: 8px;
$msv-checkout-place-order-button-margin: 10px;
$msv-checkout-side-control-margin-bottom: 24px;
$msv-checkout-side-control-first-margin-top: 20px;
$msv-checkout-side-control-first-margin-auto: 15px auto;
$msv-checkout-side-control-place-order-button-width: 100%;
$msv-checkout-side-control-first-padding: 10px 20px 20px 20px;
$msv-checkout-save-button-margin-left: 10px;
$msv-chckout-line-items-group-title-top: 42px;
$msv-chckout-line-items-group-title-left: 180px;
$msv-chckout-line-items-group-title-tablet-left: 160px;
$msv-checkout-line-items-group-title-margin-left: 37px;
$ms-checkout-invoice-side-control-first: 17px 54px 32px;
$msv-checkout-multiple-pickup-padding: 31px 40px 39px 41px;
$msv-checkout-side-ordersummary-heading-padding: 27px 57px 23px 54px;
$msv-checkout-side-ordersummary-items-margin: 0 19px;
$msv-checkout-cart-line-unit-of-measure-margin-left: 5px;
$msv-checkout-error-message-margin-right: 464px;

// checkout__lines styling
$msv-checkout-line-items-padding: 32px;
$msv-checkout-line-image-size: 108px;
$msv-checkout-line-margin-top: 20px;
$msv-checkout-line-margin-left: 20px;
$msv-checkout-line-content-width: calc(100% - 128px);
$msv-checkout-line-header-margin-bottom: 20px;
$msv-checkout-line-heading-font-weight: 700;
$msv-checkout-edit-btn-padding: 0;
$msv-checkout-edit-btn-margin-left: auto;
$msv-checkout-cart-line-quantity-margin-left: 0;
$msv-checkout-cart-line-quantity-label-margin-right: 5px;
$msv-checkout-cart-line-price-strikethrough-margin-right: 10px;
$msv-checkout-pick-up-at-store-margin-left: 128px;
$msv-checkout-pick-up-at-store-margin-top: 10px;
$msv-checkout-pick-up-at-store-margin-bottom: 20px;
$msv-checkout-email-delivery-margin-left: 128px;
$msv-checkout-email-delivery-margin-top: 10px;
$msv-checkout-email-delivery-margin-bottom: 20px;
$msv-checkout-line-items-header-margin: 27px 57px 23px 54px;

// terms-and-conditions
$msv-checkout-terms-and-conditions-margin-bottom: 15px;
$msv-checkout-terms-and-conditions-padding-right: 32px;
$msv-checkout-terms-and-conditions-padding-left: 0px;

// Mobile styles
$msv-mobile-checkout-min-width: 290px;
$msv-mobile-checkout-max-width: 100%;
$msv-mobile-checkout-padding-zero: 0;
$msv-mobile-checkout-margin: 0;
$msv-mobile-checkout-max-button-width: 100%;
$msv-mobile-checkout-place-order-margin-left: 0;
$msv-mobile-checkout-place-order-margin-top: 10px;
$msv-mobile-checkout-keep-shopping-margin-top: 20px;
$msv-mobile-checkout-header-padding: 25px 30px;
$msv-mobile-checkout-lineitems-padding-left: 20px;
$msv-mobile-checkout-lineitems-padding-right: 20px;

// Alert
$msv-checkout-error-message-padding: 22px;
$msv-checkout-error-message-margin-bottom: 20px;
$msv-checkout-error-message-width: 100%;
$msv-checkout-icon-margin-right: 8px;
$msv-checkout-error-border-radius: 10px;
$msv-checkout-error-icon-margin-right: 22px;
$msv-checkout-error-icon-padding-left: 2px;

:root {
    --msv-checkout-border: #{$msv-gray-50};
    --msv-checkout-font-size: var(--msv-body-font-size-l);
    --msv-checkout-shoppingbag-border: #{$msv-gray-50};

    // background
    --msv-checkout-side-bg: var(--msv-bg-color);
    --msv-checkout-side-multiple-pickup-bg: #{$msv-white};

    //heading
    --msv-checkout-address-heading-font-color: #{$msv-gray-20};

    // error
    --msv-checkout-error-message-bg: #{$msv-pink};
    --msv-checkout-error-message-color: #{$msv-red};

    // checkout line items
    --msv-checkout-line-heading-font-color: var(--msv-font-primary-color);
    --msv-checkout-line-title-font-color: #{$msv-gray-20};
    --msv-checkout-line-text-font-color: #{$msv-gray-20};
    --msv-checkout-line-text-font-size: var(--msv-body-font-size-m);
}

.ms-checkout {
    padding-bottom: $msv-checkout-margin-bottom;
    padding-top: $msv-checkout-margin-top;
    margin-left: $msv-checkout-margin-left-right;
    margin-right: $msv-checkout-margin-left-right;
    font-size: var(--msv-checkout-font-size);
    line-height: $msv-line-height-l;

    &__line-item {
        .msc-cart-line {
            display: flex;
            margin-top: 20px;

            &__product-image {
                position: relative;

                @include image($msv-checkout-line-image-size);
                margin-right: unset;
            }

            &__content {
                display: block;
                margin-left: 20px;
                width: calc(100% - 128px);

                .msc-cart-line__product {
                    .msc-cart-line__catalog-label {
                        font-size: 16px;
                    }
                }
            }

            &__quantity {
                .msc-cart-line__product-unit-of-measure {
                    display: none;
                }
            }
        }
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
    }

    &__btn-place-order {
        &.is-busy {
            &::before {
                @include msv-icon();
                content: $msv-Spinner;
                margin-right: $msv-checkout-icon-margin-right;
                -webkit-animation: spin 1s steps(8) infinite;
                animation: spin 1s steps(8) infinite;
            }

            cursor: progress;
        }

        @include primary-button-light();
        margin-left: $msv-checkout-place-order-button-margin;
    }

    &__btn-keep-shopping.btn-secondary {
        @include secondary-button-dark();
    }

    &__error-message {
        @include font-body-regular-m();
        width: $msv-checkout-error-message-width;
        background-color: var(--msv-checkout-error-message-bg);
        color: var(--msv-checkout-error-message-color);
        padding: $msv-checkout-error-message-padding;
        margin-bottom: $msv-checkout-error-message-margin-bottom;
        border-radius: $msv-checkout-error-border-radius;
        align-items: center;
        display: flex;

        @media screen and (min-width: ($msv-breakpoint-m + 1)) {
            margin-right: $msv-checkout-error-message-margin-right;
        }

        @include add-icon($msv-times-circle);

        &::before {
            margin-right: $msv-checkout-error-icon-margin-right;
            padding-left: $msv-checkout-error-icon-padding-left;
            font-size: $msv-font-size-l;
        }
    }

    &-section-container {
        padding-left: $msv-checkout-plain-container-padding-left;

        &__item.hidden {
            display: none;
        }
    }

    &__title {
        display: none;
    }

    &__main {
        flex: auto;
        max-width: $msv-checkout-main-max-width;
        padding-right: $msv-checkout-main-panel-padding-right;
        padding-left: 0;

        .ms-checkout__terms-and-conditions {
            padding-right: $msv-checkout-terms-and-conditions-padding-right;
            padding-left: $msv-checkout-terms-and-conditions-padding-left;
            margin-bottom: $msv-checkout-terms-and-conditions-margin-bottom;
        }
    }

    &__main-control {
        display: flex;
        justify-content: flex-start;

        .ms-checkout__btn-keep-shopping {
            order: 0;
        }

        .ms-checkout__btn-place-order {
            margin-right: 0;
            order: 1;
        }
    }

    &__side-control-first {
        display: block;
        justify-content: center;

        padding: $msv-checkout-side-control-first-padding;
        border: 0.5px solid $msv-gray-50;
        border-radius: 0 0 10px 10px;
        border-top: none;

        .ms-checkout__btn-place-order {
            width: $msv-checkout-side-control-place-order-button-width;
            margin: $msv-checkout-side-control-first-margin-auto;
        }

        .ms-checkout__btn-keep-shopping {
            width: 100%;
        }
    }

    &__side-control-second {
        display: none;
    }

    &__side-control {

        &-first,
        &-second {
            margin-bottom: $msv-checkout-side-control-margin-bottom;
        }
    }

    &__login-link {
        display: block;
        text-align: end;
        position: relative;
        text-decoration: underline;
        font-size: 14px;

        @media screen and (max-width: $msv-breakpoint-m) {
            font-size: $msv-font-size-s;
        }
    }

    &__side {
        width: $msv-checkout-side-width;
        height: fit-content;

        .msc-order-summary__heading {
            @include font-heading-h3-l();
            padding: $msv-checkout-side-ordersummary-heading-padding;

            @media screen and (max-width: $msv-breakpoint-l) {
                padding: $msv-mobile-checkout-header-padding;
            }
        }

        .msc-order-summary-wrapper {
            border-radius: 10px 10px 0 0;
            border: 0.5px solid $msv-gray-50;
            background-color: $msv-ONLY-light-gray;

            @media screen and (max-width: $msv-breakpoint-l) {
                border-radius: 10px;
            }

            .msc-order-summary__items {
                border: none;
                margin: $msv-checkout-side-ordersummary-items-margin;

                .msc-order-summary__line-loyalty {
                    order: 2;
                }

                .msc-order-summary__line-total-discounts {
                    order: 3;
                }

                .msc-order-summary__line-shipping {
                    order: 4;
                }

                .msc-order-summary__line-tax-amount {
                    order: 5;
                }

                .msc-order-summary__line-total {
                    order: 6;
                    padding: 24px 0;
                }
            }
        }

        .msc-invoice-summary-wrapper {
            @media screen and (min-width: $msv-breakpoint-m) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            &+.ms-checkout__side-control-first {
                border-top: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                margin-top: 0;
                padding: $ms-checkout-invoice-side-control-first;
            }
        }
    }

    &__has-express-side {
        margin-top: $msv-checkout-has-express-side-margin-top;
        width: $msv-checkout-side-width;
    }

    &__guided-form {
        margin-bottom: $msv-checkout-guided-form-margin-bottom;
    }

    &__guided-card {
        border-top: $msv-checkout-guided-card-border-top-width solid var(--msv-checkout-border);
        padding-bottom: $msv-checkout-guided-card-padding-bottom;
        padding-top: $msv-checkout-guided-card-padding-top;
        color: $msv-gray-40;

        &-header {
            display: flex;
        }

        &.hidden {
            display: none;
        }

        &.visted,
        &.active {
            border-top: $msv-checkout-guided-card-border-top-width solid var(--msv-checkout-border);
            color: var(--msv-checkout-address-heading-font-color);
            text-decoration: none;
        }

        &-title {
            @include font-heading-h3-l();
            display: flex;
        }

        &-title-step {
            display: none;
        }

        &-body {
            margin-top: $msv-checkout-guided-card-body-margin-top;

            &.hidden {
                display: none;
            }
        }

        &-content {
            .ms-checkout-pickup {
                &__group {
                    display: flex;
                    flex-direction: column;

                    &-lines {
                        order: 1;
                    }

                    &-datetime {
                        margin: 10px 0 40px;
                    }
                }
            }
        }

        &-btn-cancel {
            @include secondary-button-dark();
        }

        &-btn-save {
            @include primary-button-dark();
            margin: -2px 0 0 0 !important; //Note: ms-checkout__guided-card bottom margin is 30px
            // padding: 0;
            width: 100% !important;
            height: 40px;
            font-size: 14px;
            // font-weight: 500;
            // background: #000 !important;

            @media (min-width: $non-mobile) {
                height: 62px !important;
            }
        }

        &-btn-cancel,
        &-btn-save {
            margin-right: $msv-checkout-save-button-margin-left;

            &.is-submitting {
                &::before {
                    @include msv-icon();
                    content: $msv-Spinner;
                    margin-right: $msv-checkout-icon-margin-right;
                    -webkit-animation: spin 1s steps(8) infinite;
                    animation: spin 1s steps(8) infinite;
                }

                cursor: progress;
            }
        }

        &-btn-edit {
            @include link-regular-m();
            padding: $msv-checkout-edit-btn-padding;
            margin-left: $msv-checkout-edit-btn-margin-left;
            background: transparent;
            border: none;
        }

        &-footer {
            margin-top: $msv-checkout-guided-card-footer-margin-top;
            margin-bottom: $msv-checkout-guided-card-footer-margin-bottom;
            display: flex;
        }
    }

    &__line-items {
        background-color: var(--msv-checkout-side-bg);
        line-height: $msv-line-height-s;
        padding: $msv-checkout-line-items-padding;

        &-edit-cart-link {
            @include link-regular-s();
            align-items: center;
        }

        &-header {
            margin-bottom: $msv-checkout-line-header-margin-bottom;

            .ms-checkout__line-items-heading {
                color: var(--msv-checkout-line-heading-font-color);
                display: inline;
                text-transform: capitalize;

                @include font-heading-h3-l();
            }
        }

        &-delivery-group {
            .ms-checkout__line-items-group-title {
                font-weight: $msv-checkout-line-heading-font-weight;
                color: var(--msv-checkout-line-text-font-color);
            }

            .ms-checkout__line-items-group-title-multiple-pickup-pickupicon {
                @include add-icon($msv-roadster, before);
                padding-top: 10px;
                position: absolute;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-shipicon {
                @include add-icon($msv-DeliveryTruck, before);
                padding-top: 10px;
                position: absolute;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-emailicon {
                @include add-icon($msv-DeliveryTruck, before);
                padding-top: 10px;
                position: absolute;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-heading {
                @include font-heading-h5-l();
                color: var(--msv-checkout-line-text-font-color);
                margin-left: $msv-checkout-line-items-group-title-margin-left;
                padding-top: 10px;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-subheading {
                @include font-body-regular-m();
                color: var(--msv-checkout-line-text-font-color);
                position: absolute;
                left: $msv-chckout-line-items-group-title-left;
                top: $msv-chckout-line-items-group-title-top;

                @media screen and (max-width: $msv-breakpoint-m) {
                    left: $msv-chckout-line-items-group-title-tablet-left;
                }
            }

            .ms-checkout__pick-up-at-store {
                margin-left: $msv-checkout-pick-up-at-store-margin-left;
                margin-top: $msv-checkout-pick-up-at-store-margin-top;
                margin-bottom: $msv-checkout-pick-up-at-store-margin-bottom;

                .ms-checkout__store-location {
                    font-weight: var(--msv-font-weight-bold);
                }
            }

            .ms-checkout__email-delivery {
                margin-left: $msv-checkout-email-delivery-margin-left;
                margin-top: $msv-checkout-email-delivery-margin-top;
                margin-bottom: $msv-checkout-email-delivery-margin-bottom;

                @include font-body-regular-m();

                .ms-checkout__store-location {
                    font-weight: var(--msv-font-weight-bold);
                }
            }

            .msc-cart-line {
                margin-top: $msv-checkout-line-margin-top;

                &__product-image {
                    position: relative;
                    margin-right: unset;

                    .msc-image-container {
                        border: 0.5px solid var(--msv-checkout-border);
                        margin: unset;
                    }
                }

                &__quantity,
                &__product-variants {
                    color: var(--msv-checkout-line-text-font-color);

                    @include font-body-regular-m();
                }

                &__content {
                    display: block;
                    margin-left: $msv-checkout-line-margin-left;
                    width: $msv-checkout-line-content-width;

                    .msc-cart-line__product-title {
                        color: var(--msv-checkout-line-title-font-color);

                        @include font-body-bold-m();
                    }

                    .msc-cart-line-item-product-discount {
                        color: var(--msv-success-color);
                        font-size: $msv-font-size-m;
                    }

                    .msc-cart-line__quantity {
                        margin-left: $msv-checkout-cart-line-quantity-margin-left;

                        .quantity-label {
                            margin-right: $msv-checkout-cart-line-quantity-label-margin-right;
                        }

                        .quantity-value {
                            display: inline;
                            text-align: left;
                        }
                    }

                    .msc-cart-line__product-price {
                        margin-left: $msv-checkout-cart-line-quantity-margin-left;
                        text-align: left;
                        display: flex;
                        flex-wrap: wrap;

                        @include font-body-regular-m();

                        .msc-cart-line__product-unit-of-measure {
                            margin-left: $msv-checkout-cart-line-unit-of-measure-margin-left;
                        }

                        .msc-price__strikethrough {
                            color: $msv-gray-40;
                            display: inline;
                            margin-right: $msv-checkout-cart-line-price-strikethrough-margin-right;
                            text-decoration-line: line-through;
                        }

                        .msc-price__actual {
                            color: var(--msv-checkout-line-text-font-color);
                            display: inline-block;
                        }
                    }

                    .msc-cart-line__product-unit-price {
                        display: none;
                    }

                    .msc-cart-line__product-discount {
                        @include font-body-regular-m();
                        color: $msv-blue;

                        .msc-cart-line-item-product-discount-price {
                            margin-left: 10px;
                        }
                    }

                    .msc-cart-line__product-savings {
                        margin-left: 0;
                        text-align: left;
                        // hide extra price component
                        display: none;
                    }

                    .msc-cart-line__freight {
                        @include font-body-regular-m();
                    }
                }
            }
        }
    }

    .multiple-pickup {
        border-top: 1px solid var(--msv-checkout-shoppingbag-border);
        position: relative;
        padding: $msv-checkout-multiple-pickup-padding;
    }

    .multiple-pickup-enabled {
        background-color: var(--msv-checkout-side-multiple-pickup-bg);
        border: 0.5px solid $msv-gray-50;
        border-radius: 10px;
        padding: 0;
        margin-top: 20px;

        .ms-checkout__line-items-header {
            margin: $msv-checkout-line-items-header-margin;
            display: flex;
            justify-content: space-between;

            @media (max-width: $msv-breakpoint-l) {
                padding: $msv-mobile-checkout-header-padding;
                margin: 0;
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__main {
            min-width: $msv-mobile-checkout-min-width;
            max-width: $msv-mobile-checkout-max-width;
            padding-right: $msv-mobile-checkout-padding-zero;
            padding-left: $msv-mobile-checkout-padding-zero;

            // .ms-checkout__terms-and-conditions {
            //     display: none;
            // }
        }

        &__side {
            width: $msv-mobile-checkout-max-width;
        }

        &__has-express-side {
            margin-top: 0;
            width: $msv-mobile-checkout-max-width;
        }

        &__guided-card {
            &-body {
                padding-left: $msv-mobile-checkout-padding-zero;
                padding-right: $msv-mobile-checkout-padding-zero;
            }

            &-btn-save {
                width: $msv-mobile-checkout-max-button-width;
                margin-right: 10px;
            }

            &-btn-cancel {
                width: $msv-mobile-checkout-max-button-width;
            }
        }

        &__body {
            width: $msv-mobile-checkout-max-button-width;
        }

        margin-right: $msv-mobile-checkout-margin;
        margin-left: $msv-mobile-checkout-margin;

        &__main-control,
        &__side-control-first {
            display: none;
        }

        &__side-control-second {
            background: var(--msv-checkout-side-bg);
            display: block;
        }

        .ms-checkout__btn-place-order {
            margin-left: $msv-mobile-checkout-place-order-margin-left;
            margin-top: $msv-mobile-checkout-place-order-margin-top;
        }

        .ms-checkout__btn-keep-shopping {
            margin-top: $msv-mobile-checkout-keep-shopping-margin-top;
            line-height: $msv-line-height-xl;
            justify-content: center;
            padding-left: 5px;
            padding-right: 5px;
        }

        &__line-items {

            .ms-checkout__line-items-header,
            .ms-checkout__line-items-delivery-group {
                padding-left: $msv-mobile-checkout-lineitems-padding-left;
                padding-right: $msv-mobile-checkout-lineitems-padding-right;
                margin: 0;
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-l) {

        .ms-checkout__btn-keep-shopping,
        .ms-checkout__btn-place-order {
            width: $msv-mobile-checkout-max-button-width;
        }

        .ms-checkout__btn-place-order {
            margin-top: 20px;
            margin-left: 0;
        }

        .ms-checkout__main-control {
            flex-direction: column;
        }
    }

    .ms-checkout-shipping-address {
        .msc-address-form__item-street {
            .MicrosoftMap {
                width: 100%;

                .as_container_search {
                    @media screen and (min-width: $msv-breakpoint-l) {
                        width: calc(100% - (#{$msv-checkout-side-width} + #{$msv-checkout-main-panel-padding-right}));
                    }
                }
            }
        }
    }
}

//  -------------- BESTSELLER EXTENDED/CUSTOM STYLING - TODO: Move into correct extend file/location


$non-mobile: 600px;

.ms-checkout {
    margin: 0;
    padding: 0;
    // box-shadow: inset 0px 10px 10px -10px rgba(0, 0, 0, 0.1); //TODO: Move shadow into header.

    /** { box-shadow: 0 0 2px red inset; }*/

    &__body {
        padding: 0;

        @media (min-width: $non-mobile) {
            gap: 24px;
        }
    }

    @media (min-width: $non-mobile) {
        &__side {
            flex: 1;
            width: auto;
        }

        &__main {
            flex: 1;
            margin: -50px 0 0 0;
            padding: 0 !important;
            width: auto;
            max-width: none;
        }
    }

    &__title {
        display: block;
        margin: 0 0 10px 0;
        padding: 26px 0 0 0;
        font-family: 'Conv_EuropaBold';
        font-size: 27px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;

        @media (min-width: $non-mobile) {
            margin: 30px 0;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
        }
    }

    &__line-items {
        margin: 0 !important;
        padding: 0;

        &-header,
        &-group-title-multiple-pickup-shipicon {
            display: none !important;
        }

        &-group-title-multiple-pickup-heading {
            float: left;
            width: 60%;
            margin: 0 !important;
            padding: 0 !important;
            font-size: 12px !important;
            font-weight: 400 !important;
            line-height: 26px !important;
            text-transform: uppercase;

            @media (min-width: $non-mobile) {
                font-size: 14px !important;
                font-weight: 400 !important;
            }
        }

        &-group-title-multiple-pickup-subheading {
            float: right;
            width: 40%;
            position: relative !important;
            top: 0 !important;
            left: 0 !important;
            font-size: 12px !important;
            font-weight: 400 !important;
            text-transform: uppercase;
            line-height: 26px !important;
            text-align: right;

            @media (min-width: $non-mobile) {
                font-size: 14px !important;
                font-weight: 400 !important;
            }
        }

        &-list {
            clear: both;
            display: flex;
            flex-direction: column;
            gap: 18px;
            margin: 0 0 30px 0;
            padding: 16px 0 0 0; //Note: margin is not possible when float/clear is used.

            .msc-cart-line {
                margin: 0;
                padding: 0;
                gap: 13px;

                @media (min-width: $non-mobile) {
                    gap: 14px;
                }

                &__product-image,
                &__product-image .msc_image {
                    width: 128px;
                    min-width: 128px;
                    height: auto;

                    @media (min-width: $non-mobile) {
                        width: 214px;
                        min-width: 214px;
                    }
                }

                &__product-title {
                    font-family: 'Conv_EuropaRegular' !important;
                    font-size: 13px !important;
                    font-weight: 600 !important;
                    text-transform: uppercase;

                    @media (min-width: $non-mobile) {
                        font-size: 18px !important;
                        font-weight: 600 !important;
                    }
                }

                &__product-variant-item,
                &__product-price {
                    margin: 7px 0 0 0;
                    font-size: 12px !important;
                    font-weight: 400 !important;
                    text-transform: uppercase;

                    @media (min-width: $non-mobile) {
                        margin: 4px 0 0 0;
                        font-size: 15px !important;
                        font-weight: 400 !important;
                    }
                }

                @media (min-width: $non-mobile) {
                    &__product-variants {
                        margin: 8px 0 0 0;
                    }

                    &__product-price {
                        margin: 16px 0 0 0;
                    }
                }

                &__product-discount,
                &__quantity {
                    display: none;
                }

                &__content {
                    margin: 0;
                }
            }
        }
    }

    .multiple-pickup {
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: 0;

        &-enabled {
            background: none;
            border: 0;
            border-radius: 0;
        }
    }

    .msc-order-summary {

        &-wrapper {
            border-radius: 0;
            border: none;
            background-color: $msv-ONLY-light-gray;
            padding: 12px;
            margin: 12px 0 !important;
            margin-top: 12px !important;

            @media (min-width: $non-mobile) {
                padding: 36px;
            }
        }

        &__heading {
            margin: 0 0 13px 0;
            padding: 0;
            border: 0 !important;
            font-size: 14px !important;
            font-weight: 300 !important;
            text-transform: uppercase;

            @media (min-width: $non-mobile) {
                font-size: 16px !important;
                font-weight: 700 !important;
                line-height: 20px !important;
            }
        }

        &__items {
            margin: 0 !important;
            padding: 0;
            border: 0;
            border-radius: 0;
            background: #F6F6F6;
        }

        &__line-sub-total,
        &__line-shipping,
        &__line-total-discounts {
            display: flex;
            padding: 6px 0;
            justify-content: space-between;

            // @media (min-width: $non-mobile) {
            //     padding: 26px 19px 0 19px;
            // }
        }

        &__line-total {
            display: flex;
            margin: 9px 0 0 0;
            padding: 12px 0 0 0 !important;
            border-top: 1px solid #D2D2D2;
            justify-content: space-between;

            @media (min-width: $non-mobile) {
                margin: 19px 0 0 0;
                padding: 24px 0 0 0 !important;
            }
        }

        &__label {
            font-family: 'Conv_EuropaLight' !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            text-transform: uppercase !important;

            @media (min-width: $non-mobile) {
                font-size: 16px !important;
            }
        }

        &__value {
            font-family: 'Conv_EuropaLight' !important;
            font-size: 14px !important;
            font-weight: 600 !important;
            text-transform: uppercase !important;

            @media (min-width: $non-mobile) {
                font-size: 16px !important;
            }
        }
    }

    &__guided {
        &-form {
            margin: 0;
        }

        &-card {
            margin: 14px 0 14px 0;
            padding: 14px 0 0 0;
            border-color: #000 !important;

            @media (min-width: $non-mobile) {
                margin: 39px 0 45px 0;
                padding: 21px 0 0 0;
            }

            &-header {
                align-items: start;
            }

            &-title {
                margin: 0 0 14px 0;
                font-family: 'Conv_EuropaRegular' !important;
                font-size: 14px !important;
                font-weight: 300 !important;
                text-transform: uppercase !important;

                @media (min-width: $non-mobile) {
                    font-size: 16px !important;
                }

                &-step {
                    display: inline;
                    margin: 0 6px 0 0;
                }
            }

            &-btn-edit {
                color: var(--msv-checkout-address-heading-font-color) !important;
                font-size: 14px !important;
                font-weight: 300 !important;
                text-decoration: underline !important;
                outline: none !important;
            }

            &-body {
                margin: 0;
                padding: 0;

                .ms-checkout-section-container {
                    margin: 0;
                    padding: 0;
                }
            }

            &-content {
                @media (min-width: $non-mobile) {
                    margin: 24px 0 0 0;
                }
            }

            &-footer {
                margin: 0 !important;
                padding: 0;
            }

            .btn-primary.disabled,
            .btn-primary:disabled {
                border-color: #000 !important;
            }

            &-btn-save {
                @include primary-button-dark();
                margin: 12px 0 0 0 !important; //Note: ms-checkout__guided-card bottom margin is 30px
                // padding: 0;
                width: 100% !important;
                height: 40px;
                font-size: 14px;
                // font-weight: 500;
                // background: #000 !important;

                @media (min-width: $non-mobile) {
                    margin: 32px 0 0 0 !important;
                    height: 62px !important;
                }
            }

            &-btn-cancel {
                display: none;
            }
        }
    }

    .ms-checkout-guest-profile {

        &.is-invalid {
            border-color: red;

            input {
                color: red;
            }
        }

        &__input-text {
            @include form-control();
            max-width: none;

            &.form-control:focus {
                box-shadow: none;
                border-color: #1D1D1D;
            }

            // @media (max-width: $non-mobile) {
            //     height: 39px;
            // }
        }
    }

    .msc-address-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px;

        @media (min-width: $non-mobile) {
            gap: 12px;
        }

        &__item,
        .ms-checkout-guest-profile__input {
            @include form-control();
            margin: 0;
            display: flex;
            justify-content: center;
            max-width: 100%;

            &:has(.msc-address-form__input:focus),
            &:has(.msc-address-form__input:not([value=""])),
            &:has(.msc-address-form__input input:is(:-webkit-autofill, :autofill)),
            &:has(.msc-address-form__input input:is(:-webkit-autofill:focus, :autofill:focus)),
            &:has(.msc-address-form__dropdown) {
                label {
                    position: static;
                    font-size: 10px !important;
                    // color: red;
                }

                input {
                    height: fit-content;
                    padding: 0 !important;
                    z-index: 9999;
                }

                select {
                    margin-left: -4px;
                }
            }

            // &.adress-form__item-invalid {
            //     border-color: red !important;

            //     .msc-address-form__alert {
            //         color: red;
            //         margin-bottom: -36px !important;
            //     }
            // }

            &-zipcode {
                width: 35%;
            }

            &-city {
                width: 55%;
                flex: 1;
            }
        }

        &__item.address-form__item-invalid {
            border-color: red !important;
            margin-bottom: 18px;

            .msc-address-form {

                &__alert,
                &__label {
                    color: red;
                }

                &__alert {
                    margin-top: 24px;
                    margin-bottom: -30px !important;
                }

                &__input {
                    border: none;
                }
            }

            &:has(input:focus) {
                .msc-address-form__alert {
                    margin-top: 16px;
                    margin-bottom: -24px !important;
                }
            }
        }


        // .input-box {
        //     position: relative;

        .msc-address-form__input,
        .msc-address-form__dropdown,
        .ms-checkout-guest-profile__input-text.form-control,
        .iti input {
            padding: 0;
            border: none;
            // display: none;
            // @include form-control();

            // @media (max-width: $non-mobile) {
            //     height: 39px;
            // }

            //TODO: dont target empty value-atributte + not empty placeholder-attribute. hide placeholder (conflict w. label layer)
            &:focus+.msc-address-form__label,
            &:not([value=""])+.msc-address-form__label {
                top: 0;
                font-size: 11px !important;

                @media (min-width: $non-mobile) {
                    font-size: 14px !important;
                }
            }
        }

        .iti input {
            border: 1px solid #1D1D1D !important;
        }



        span.msc-address-form__input {
            //Note: iti dropdown (phone)
            display: block;
            border: 0 !important;
            padding: 0;

            .iti {
                display: block;
            }
        }

        .msc-address-form__label {
            margin: 0;
            // margin-top: 16px;
            position: absolute;
            // top: 50%;
            left: 10px;
            // transform: translateY(-50%);
            z-index: 9999;
            color: #888;
            pointer-events: none;
            background-color: white;
            padding: 0;
            transition: 0.1s ease all;
            font-size: 14px !important;
            font-weight: 400 !important;
            color: #959595;
        }

        // }

        &__button-save {
            margin: 2px 0 0 0; //Note: gab is 12
            padding: 0;
            height: 40px;
            font-size: 14px;
            font-weight: 500;
            background: #000;

            @media (min-width: $non-mobile) {
                margin: 8px 0 0 0; //Note: gab is 18
                height: 62px;
            }
        }

        &__button-cancel {
            display: none;
        }
    }

    &-shipping-address {
        &__group-images {
            display: none;
        }

        .msc-address-detail {
            margin: 0;
            // padding: 0 !important;
            font-size: 14px !important;
            font-weight: 400 !important;
        }
    }

    &-billing-address {
        &__heading {
            margin: 14px 0 11px 0 !important;
            padding: 0;
            border: 0 !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            text-transform: uppercase;

            @media (min-width: $non-mobile) {
                margin: 28px 0 11px 0 !important;
            }
        }

        &__shipping-address-label {
            margin: 0 0 14px 0 !important;
            padding: 0;

            @media (min-width: $non-mobile) {
                margin: 0 0 28px 0 !important;
            }
        }

        &__shipping-address-checkbox-text {
            margin: 0 !important;
            padding: 0;
            font-size: 12px !important;
            font-weight: 400 !important;

            @media (min-width: $non-mobile) {
                font-size: 14px !important;
            }

            &::before {
                content: "";
                display: inline-block;
                margin: 0 8px 0 0;
                padding: 0;
                width: 20px;
                height: 20px;
                border: 1px solid #000;
                background-color: #FFF;
                transition: all 0.1s;
                text-align: center;
                line-height: 16px;
                vertical-align: middle;
            }
        }

        &__input-checkbox {
            display: none;
        }

        &__input-checkbox:checked+&__shipping-address-checkbox-text::before {
            content: "\2713"; //TODO: Set correct icon
            color: #FFF;
            background-color: #000;
            transition: all 0.1s;
        }

        .msc-address-detail {
            margin: 0;
            padding: 0 !important;
            font-size: 14px !important;
            font-weight: 400 !important;
        }
    }

    &-delivery-options {
        &__list {
            display: flex;
            flex-direction: column;
            gap: 18px;

            @media (min-width: $non-mobile) {
                gap: 38px;
                margin: 24px 0 0 0;
            }
        }

        &__option {
            display: block !important;
            margin: 0 !important;
            padding: 0;

            div:first-child {
                display: flex !important;
                justify-content: space-between;
            }

            div:not(:first-child) {


                margin-left: 40px;

                @media (min-width: $non-mobile) {
                    margin-left: 49px;
                }

                span {
                    font-size: 14px;
                }

            }

            &_wrapper-box-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 8px;
                justify-content: flex-end;

                &:not(:first-child) {
                    padding: 0 0 0 40px;

                    @media (min-width: $non-mobile) {
                        padding: 0 0 0 48px;
                    }
                }

                .ms-centiro__preferred-store-btn {
                    background-color: white;
                    text-transform: uppercase;
                    font-size: 12px;
                    border-width: 1px;
                    padding: 8px 12px;
                    margin-bottom: 8px;
                }
            }
        }

        &__option-selected {
            display: grid !important;
            grid-template-columns: auto auto;
            vertical-align: middle;

            div {
                text-align: right;
            }

            >span {
                display: block;
            }

            .ms-checkout-delivery-options__address {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                line-height: 21px;

                &__name {
                    order: 1;
                    grid-column: 1/-1;
                }

                &__street {
                    order: 2;
                    grid-column: 1/-1;
                }

                &__zipcode {
                    order: 3;
                    grid-column: 1;
                }

                &__city {
                    order: 4;
                    grid-column: 2/5;
                }

                &__country {
                    order: 5;
                    grid-column: 1/-1;
                }

            }
        }

        &__option &__description,
        &__option-selected &__description {
            margin: 0 !important;
            padding: 0;
            font-size: 12px !important;
            font-weight: 400 !important;
            text-transform: uppercase;
            max-width: none;

            @media (min-width: $non-mobile) {
                font-size: 14px !important;
            }
        }

        &__option &__description::before {
            content: "";
            display: inline-block;
            margin: 0 21px 0 0;
            padding: 0;
            width: 19px;
            height: 19px;
            border: 1px solid #000;
            border-radius: 19px;
            background-color: #FFF;
            box-shadow: inset 0 0 0 5px #FFF;
            transition: all 0.1s;
            text-align: center;
            vertical-align: middle;

            @media (min-width: $non-mobile) {
                margin: 0 14px 0 16px;
            }
        }

        &__label {
            margin: 0 !important;
            padding: 0;
        }

        &__input-radio {
            display: none;
        }

        &__input-radio:checked+&__description::before {
            background-color: #000;
            transition: all 0.1s;
        }

        &__address {
            font-size: 14px !important;
            font-weight: 400 !important;
        }

        &__price {
            margin: 0 !important;
            font-size: 12px !important;
            font-weight: 400 !important;
            text-transform: initial !important;

            @media (min-width: $non-mobile) {
                font-size: 14px !important;
            }
        }
    }

    &-payment-instrument {
        &__mutation-observer {
            margin: 0 !important;
            padding: 0;
        }

        &__add iframe {
            width: 100% !important;
            height: 330px !important;
        }
    }

    &__main-control {
        display: block;
        margin-bottom: 24px
    }

    &__side-control-first {
        border: none;
        padding: 0;

        @media (min-width: $non-mobile) {
            display: none;
        }
    }

    .ms-checkout__terms-and-conditions {
        padding-right: $msv-checkout-terms-and-conditions-padding-right;
        padding-left: $msv-checkout-terms-and-conditions-padding-left;
        margin-bottom: $msv-checkout-terms-and-conditions-margin-bottom;
    }

    &__btn-place-order {
        @include primary-button-dark();
        margin: -2px 0 0 0 !important; //Note: ms-checkout__guided-card bottom margin is 30px
        // padding: 0;
        width: 100% !important;
        height: 40px;
        font-size: 14px;
        // font-weight: 500;
        // background: #000 !important;

        @media (min-width: $non-mobile) {
            height: 62px !important;
        }
    }

    &__btn-keep-shopping.btn-secondary {
        margin-top: 12px;
        width: 100% !important;
    }
}