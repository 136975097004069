@import "../common/00-settings/colors.scss";
@import "../common/00-settings/font.scss";
@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/01-tools/mixins.scss";
@import "../common/00-settings/icons.scss";
@import "../common/02-generic/form.scss";
@import "../common/02-generic/button.scss";

$msv-store-selector-phone-section-padding-top: 13px;
$msv-store-selector-location-line-shop-address-padding-top: 2px;
$msv-store-selector-location-line-shop-address-padding-right: 15px;
$msv-store-selector-button-border-radius: 3px;
$msv-store-selector-input-border-radius: 3px;
$msv-store-selector-input-padding: 0 16px;
$msv-store-selector-input-height: 49px;
$msv-store-selector-input-width: 87%;
$msv-store-selector-button-height: 51px;
$msv-store-selector-button-width: 60px;
$msv-store-selector-button-margin-left: 4px;
$msv-store-selector-search-form-padding: 0 0 20px 0;
$msv-store-selector-search-form-padding-mobile: 0 16px 10px 16px;
$msv-store-selector-location-line-item-margin-top: 25px;
$msv-breakpoint-modal-m: 450px;
$msv-store-selector-search-form-margin-bottom: 5px;
$msv-store-locations-max-height:400px;
$pickup-option-list-margin: 5px;
$pickup-option-list-item-padding-left: 16px;
$pickup-option-list-item-padding-bottom: 10px;
$pickup-option-list-item-padding-top: 7px;
$pickup-option-list-item-padding-right: 5px;
$pickup-option-height: 52px;
$pickup-option-list-margin: 0;
$msv-store-location-pickup-option-line-height: 24px;
$msv-store-selector-input-box-sizing: border-box;
$msv-store-selector-input-padding-margin: 51px;
$msv-store-selector-location-line-pickup-list-toggle-padding-left: 12px;
$msv-store-location-pickup-option-filter-padding: 10px;
$msv-store-selector-modal-content-padding: 24px 0 0 0;
$msv-store-selector-map-heading-maring-bottom: 10px;
$msv-store-selector-found-locations-margin-bottom: 16px;
$msv-store-selector-found-locations-padding-left: 36px;
$msv-store-selector-found-locations-padding-right: 23px;
$msv-store-selector-found-locations-padding-mobile: 0 16px;
$msv-store-selector-found-locations-margin-bottom-mobile: 12px;
$msv-store-selector-no-locations-padding-left: 36px;
$msv-store-selector-no-locations-padding-right: 23px;
$msv-store-selector-no-locations-padding-mobile: 16px 16px;
$msv-store-selector-modal-header-padding-left: 36px;
$msv-store-selector-modal-header-padding-right: 36px;
$msv-store-selector-modal-header-margin-bottom: 16px;
$msv-store-selector-modal-close-button-padding: 4px;
$msv-store-selector-navbar-container-padding-right: 20px;
$msv-store-selector-navbar-container-first-child-margin-top: 22px;
$msv-store-selector-navbar-maptypebuttoncontainer-margin-right: 20px;
$msv-store-selector-zoom-in-out-margin-top: -28px;
$msv-store-selector-map-style-selector-margin-right: 22px;
$msv-store-selector-modal-footer-padding: 13px 40px;
$msv-store-selector-modal-footer-mobile-padding: 13px 16px 17px 16px;
$msv-store-selector-search-margin-bottom: 0px;
$msv-store-selector-search-form-padding-left: 36px;
$msv-store-selector-search-form-padding-right: 36px;
$msv-store-selector-map-container-padding-left: 36px;
$msv-store-selector-map-container-padding-right: 23px;
$msv-store-selector-see-all-stores-margin-top: 30px;
$msv-store-selector-see-all-stores-margin-left: 36px;
$msv-store-selector-see-all-stores-margin-right: 23px;
$msv-store-selector-see-all-stores-mobile-padding: 5px 0;
$msv-store-selector-search-header-margin-top: 16px;
$msv-store-selector-search-header-padding-left: 36px;
$msv-store-selector-search-header-padding-right: 23px;
$msv-store-selector-search-header-pickup-menu-padding: 16px 5px;
$msv-store-selector-search-header-heading-margin: 16px 8px;
$msv-store-selector-store-hours-details-margin-bottom: 13px;
$msv-store-selector-locations-margin-right: 5px;
$msv-store-selector-locations-padding-right: 5px;
$msv-store-selector-locations-padding-left: 36px;
$msv-store-selector-locations-margin-top: 29px;
$msv-store-selector-locations-mobile-padding: 0 8px 10px 16px;
$msv-store-selector-locations-mobile-margin-right: 4px;
$msv-store-selector-location-padding: 20px 25px 20px 0px;
$msv-store-selector-location-margin-bottom: 10px;
$msv-store-selector-location-tablet-mobile-padding: 20px 56px 20px 56px;
$msv-store-selector-wrapper-margin-bottom: 20px;
$msv-store-selector-wrapper-padding-bottom: 25px;
$msv-store-selector-wrapper-mobile-margin-bottom: 20px;
$msv-store-selector-wrapper-mobile-padding-bottom: 20px;
$msv-store-selector-location-mobile-padding: 14px 0;
$msv-store-selector-set-preferred-store-padding: 16px 18px 16px 50px;
$msv-store-selector-set-preferred-store-mobile-padding: 13px 50px;
$msv-store-selector-set-preferred-store-mobile-margin-top: 14px;
$msv-store-selector-line-item-preferred-store-padding: 16px 18px 16px 50px;
$msv-store-selector-line-item-preferred-store-mobile-padding: 16px 0;
$msv-store-selector-line-item-preferred-store-mobile-margin-top: 14px;
$msv-store-selector-location-line-header-margin-bottom: 13px;
$msv-store-selector-location-line-stock-status-margin-bottom: 13px;
$msv-store-selector-location-line-item-header-margin-bottom: 10px;
$msv-store-selector-location-line-item-header-padding-left: 0px;
$msv-store-selector-location-line-item-header-mobile-margin-bottom: 13px;

//style presets
:root {
    --msv-store-select-form-bg: #{$msv-gray-100};
    --msv-store-select-background-color: #{$msv-white};
    --msv-store-select-form-input-border: #{$msv-gray-50};

    //body title
    //--msv-store-select-title-font-color: var(--msv-accent-brand-color);
    --msv-store-select-title-font-color: color: $msv-black-10;


    //body text
    --msv-store-select-text-font-size: var(--msv-body-font-size-m);
    --msv-store-select-text-font-color: var(--msv-font-primary-color);
    //--msv-store-select-distance-color: var(--msv-accent-brand-color);
    --msv-store-select-distance-color: $msv-black-10;

    //Link
    --msv-store-select-link-size: var(--msv-body-font-size-s);
    --msv-store-select-link-color: var(--msv-font-primary-color);
    --msv-store-select-divider-color: #{$msv-gray-300};

    // pickup option list icon color
    --ms-pickup-option-icon-color: #{$msv-blue};
    --ms-pickup-option-error-color: #{$msv-red};
}

@mixin close-button() {
    @include add-icon($msv-Cancel);
    @include vfi();
    background-color: transparent;
    border: 0;
    color: var(--msv-font-primary-color);
    font-size: 20px;
    position: absolute;
    cursor: pointer;
}

@mixin store-card-icon {
    @include add-icon($msv-store, before);

    &::after {
        width: 40px;
        height: 40px;
        content: "";
        position: absolute;
        border-radius: 0 8px 8px 0;
        border: 0.5px solid $msv-gray-50;
        border-left: none;
    }

    &::before {
        position: absolute;
        color: $msv-gray-20;
        z-index: 1;
    }
}

.ms-store-select {
    overflow-y: hidden;
    background-color: $msv-white;

    .msc-modal__content {
        padding: $msv-store-selector-modal-content-padding;
    }

    &__body {
        &_wrapper {
            width: 100%;
        }
    }

    &__store_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    &__map {
        .ms-map__heading {
            display: none;
            margin-bottom: $msv-store-selector-map-heading-maring-bottom;
        }

        .ms-store-select__location-wrapper.selected {
            border: 1px solid $msv-blue;
        }

        .ms-store-select__store_container {
            width: 100%;
            display: flex;
            flex-direction: row;

            @media (max-width: $msv-breakpoint-m) {
                flex-direction: column;
            }

            @media (min-width: $msv-breakpoint-modal-m) and (max-width: $msv-breakpoint-m) {
                &.show {
                    width: 100%;
                }
            }
        }
    }

    &.ms-store-select__map {
        .ms-store-select__map_container {
            width: 100%;
            display: block;
            // right: 0;
            position: static;
            // top: 0;
            height: 100%;

            @media (min-width: $msv-breakpoint-modal-m) and (max-width: $msv-breakpoint-m) {
                &.hide {
                    display: none;
                }
            }

            .ms-map {
                min-height: 100%;
                flex: 1;

                &__body {
                    height: 100%;
                }
            }
        }

        .ms-store-select__locations.show {
            margin-top: 0;
        }
    }

    &__found-locations {
        display: none;

        &__text {
            @include font-body-regular-m();
            color: var(--msv-store-select-text-font-color);
            margin-bottom: $msv-store-selector-found-locations-margin-bottom;
            padding-left: $msv-store-selector-found-locations-padding-left;
            padding-right: $msv-store-selector-found-locations-padding-right;

            @media screen and (max-width: $msv-breakpoint-m) {
                padding: $msv-store-selector-found-locations-padding-mobile;
                margin-bottom: $msv-store-selector-found-locations-margin-bottom-mobile;
            }
        }

        &__count,
        &__radius {
            font-weight: $msv-font-weight-700;
        }
    }

    &__no-locations {
        @include font-body-regular-m();
        color: var(--msv-store-select-text-font-color);
        padding-left: $msv-store-selector-no-locations-padding-left;
        padding-right: $msv-store-selector-no-locations-padding-right;
        min-height: 370px;

        @media screen and (max-width: $msv-breakpoint-m) {
            padding: $msv-store-selector-no-locations-padding-mobile;
        }
    }

    &__modal-body {
        min-height: 400px;
        margin-bottom: 0;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        padding: 0;

        @media screen and (max-width: $msv-breakpoint-m) {
            flex: 1 1 auto;
            max-height: 100%;
        }
    }

    &__modal-header, .msc-modal__header {
        padding-left: $msv-store-selector-modal-header-padding-left;
        padding-right: $msv-store-selector-modal-header-padding-right;
        margin-bottom: $msv-store-selector-modal-header-margin-bottom;

        .msc-modal__close-button {
            z-index: 1;
            background-color: var(--msv-store-select-background-color);
            padding: $msv-store-selector-modal-close-button-padding;
            top: 22px;
            right: 36px;

            @media screen and (max-width: $msv-breakpoint-m) {
                /* stylelint-disable-next-line declaration-no-important -- To override important styling for modal select element. */
                top: 15px !important;
                right: 22px;
                left: auto;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
            height: auto !important;
            /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
            padding: 19px 16px 15px 15px !important;
            /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
            border: none !important;
        }

        .msc-modal__title {
            @include font-heading-h3-l();
        }
    }

    .MicrosoftMap {
        .NavBar_Container {
            /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
            right: unset !important;
            /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
            left: 22px !important;
            width: 100%;
            padding-right: $msv-store-selector-navbar-container-padding-right;

            .NavBar_Button:first-child {
                margin-top: $msv-store-selector-navbar-container-first-child-margin-top;
            }

            .NavBar_MapTypeButtonContainer {
                margin-right: $msv-store-selector-navbar-maptypebuttoncontainer-margin-right;
            }
        }

        .bm_LocateMeControl {
            float: left;
        }

        a#ZoomInButton,
        a#ZoomOutButton {
            float: left;
            margin-bottom: unset;

            @media (min-width: $msv-breakpoint-m) {
                margin-top: $msv-store-selector-zoom-in-out-margin-top;
            }

            @media (max-width: $msv-breakpoint-m - 1) {
                margin-top: unset;
            }
        }

        .NavBar_Container.compact {
            a#ZoomInButton,
            a#ZoomOutButton {
                margin-top: unset;
            }
        }

        div#MapStyleSelector {
            margin-right: $msv-store-selector-map-style-selector-margin-right;
        }
    }

    &.msc-modal__dialog.msc-modal-input-required .msc-modal__content .msc-modal__header .msc-modal__title {
        @media screen and (max-width: $msv-breakpoint-m) {
            text-align: left;
        }
    }

    &.msc-modal__dialog.ms-store-select.msc-modal-input-required .ms-store-select__modal-body.msc-modal__body {
        @media screen and (max-width: $msv-breakpoint-m) {
            padding: 0;
        }
    }

    &__modal-footer {
        display: none;
        padding: $msv-store-selector-modal-footer-padding;
        border-top: 0.5px solid $msv-gray-50;
        box-sizing: border-box;

        @media screen and (max-width: $msv-breakpoint-m) {
            /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
            padding: $msv-store-selector-modal-footer-mobile-padding !important;
        }
    }

    &__search {
        display: none; // hide until search function works
        padding: $msv-store-selector-search-form-padding;
        margin-bottom: $msv-store-selector-search-margin-bottom;
        box-shadow: none;
        width: 100%;

        @media screen and (max-width: $msv-breakpoint-m) {
            padding: $msv-store-selector-search-form-padding-mobile;
            margin-bottom: 10px;
        }

        &-form {
            flex: auto;
            display: flex;
            position: relative;
            align-items: center;
            padding-left: $msv-store-selector-search-form-padding-left;
            padding-right: $msv-store-selector-search-form-padding-right;

            .MicrosoftMap {
                /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Overriding the default CSS added by Bing AutoSuggest. */
                position: static !important;

                .as_container_search {
                    left: 0;
                    top: 52px;
                    width: calc(
                        100% - (#{$msv-store-selector-button-width}
                        + #{$msv-store-selector-button-margin-left}
                        + #{$msv-store-selector-input-padding-margin} - 11px)
                    );

                    @media screen and (min-width: $msv-breakpoint-m + 1) {
                        padding-left: $msv-store-selector-map-container-padding-left;
                    }

                    .asOuterContainer {
                        border-width: 1px;
                        background-color: $msv-white;
                        height: 360px;
                        overflow-y: scroll;
                    }
                }

                .as_container {
                    .suggestLink {
                        color: var(--msv-store-select-link-color);
                        font-size: var(--msv-store-select-link-size);
                    }

                    .bingLogoContainer {
                        /* stylelint-disable-next-line declaration-no-important -- To override inline styling. */
                        display: block !important;
                        border-top: 0.5px solid var(--msv-store-select-form-input-border);
                        height: 56px;
                    }

                    .bingLogoLight {
                        position: relative;
                        margin: auto;
                        top: 24px;
                    }
                }
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                padding: 0;

                .MicrosoftMap {
                    .as_container_search {
                        width: calc(100% - 71px);
                    }
                }
            }
        }

        &-see-all-stores {
            display: none;
            color: var(--msv-store-select-link-color);
            text-decoration: underline;

            @include font-body-regular-s();
            cursor: pointer;
            width: fit-content;
            position: absolute;
            margin-top: $msv-store-selector-see-all-stores-margin-top;
            margin-left: $msv-store-selector-see-all-stores-margin-left;
            margin-right: $msv-store-selector-see-all-stores-margin-right;

            @media screen and (max-width: $msv-breakpoint-m) {
                padding: $msv-store-selector-see-all-stores-mobile-padding;
            }

            &:hover, &:focus {
                text-decoration: underline;
            }

            &:focus {
                outline: none;
            }
        }

        &-header {
            display: flex;
            flex-direction: row-reverse;
            margin-top: $msv-store-selector-search-header-margin-top;
            padding-left: $msv-store-selector-search-header-padding-left;
            padding-right: $msv-store-selector-search-header-padding-right;

            @media screen and (max-width: $msv-breakpoint-m) {
                padding: 0;
            }

            &-pickup {
                align-self: flex-start;
                align-items: self-end;
                position: relative;
                border: 0.5px solid #C4C4C4;
                border-radius: 3px;
                z-index: 99;

                &-menu {
                    @include form-input-box();
                    @include add-icon($msv-ChevronDown, after);
                    border: none;
                    padding: $msv-store-selector-search-header-pickup-menu-padding;
                    height: auto;

                    &::after {
                        font-size: 20px;
                        vertical-align: bottom;
                        padding-left: $msv-store-location-pickup-option-filter-padding;
                    }

                    &:focus {
                        outline: none;
                        filter: none;
                    }

                    &:active {
                        box-shadow: none;
                    }
                }

                &-list {
                    display: block;
                    position: absolute;
                    width: 100%;

                    &-item {
                        background-color: var(--msv-store-select-background-color);

                        &:hover {
                            background-color: var(--msv-store-select-background-color);
                            cursor: pointer;
                            text-decoration: none;
                        }
                    }

                    &-link {
                        border: none;
                        line-height: $msv-store-location-pickup-option-line-height;
                        padding: $msv-store-location-pickup-option-filter-padding;
                        text-align: center;
                        width: 100%;
                        outline: none;

                        @include font-body-regular-s();

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            &-heading {
                @include font-body-regular-s();
                margin: $msv-store-selector-search-header-heading-margin;
            }
        }

        &-input, .msc-form-control {
            @include form-input-box();
            -webkit-appearance: none;
            padding: $msv-store-selector-input-padding;
            height: $msv-store-selector-input-height;
            width: calc(100% - (#{$msv-store-selector-button-width} + #{$msv-store-selector-button-margin-left} + #{$msv-store-selector-input-padding-margin}));

            @include font-body-regular-s();
        }

        &-button {
            @include secondary-button-dark();

            // border-radius: $msv-store-selector-button-border-radius;
            height: $msv-store-selector-button-height;
            min-width: 20px !important;
            order: 2;
            font-size: unset;
            padding: unset;
            width: $msv-store-selector-button-width !important;
            margin-left: $msv-store-selector-button-margin-left;
            flex: 1;

            &:hover {
                cursor: pointer;
            }

            @include add-icomoon-icon($ico-Search, before);

            &::before {
                font-family: 'icomoon' !important;
                min-width: 20px !important;
                width: $msv-store-selector-button-width !important;
                text-align: center;
            }

            &:focus::before {
                @include msv-icon();
                content: $msv-Search;
                width: $msv-store-selector-button-width;
                text-align: center;
                position: static;
                border-radius: unset;
                padding: 14px 0;
                margin: 3px;
            }
        }

        .search-btn-disabled {
            background-color: $msv-gray-300;
        }
    }

    &__toggle-view {
        display: none;
        cursor: pointer;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }

    &__store-hours-details {
        @include font-body-regular-s();
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $msv-store-selector-store-hours-details-margin-bottom;

        .ms-store-select__store-hours-day {
            flex: 0 0 40%;
            margin-right: 12px;

            abbr {
                text-decoration: none;
            }
        }

        .ms-store-select__store-hours-time {
            flex: 0 0 50%;
        }
    }

    &__locations {
        max-height: $msv-store-locations-max-height;
        overflow-y: scroll;
        margin-right: $msv-store-selector-locations-margin-right;
        scroll-behavior: smooth;
        padding-left: $msv-store-selector-locations-padding-left;
        padding-right: $msv-store-selector-locations-padding-right;
        margin-top: $msv-store-selector-locations-margin-top;

        @media screen and (max-width: $msv-breakpoint-m) {
            padding: $msv-store-selector-locations-mobile-padding;
            margin-right: $msv-store-selector-locations-mobile-margin-right;
            max-height: none;
        }
    }

    @media (forced-colors: active) and (prefers-color-scheme: dark) { //to change the scrollbar color in store locator when user set high contrast theme
        &__locations::-webkit-scrollbar-thumb {
            background-color: var(--msv-store-select-background-color);
            outline: 1px solid $msv-white;
            border-radius: 10px;
        }
    }

    @media (forced-colors: active) and (prefers-color-scheme: light) {
        //to change the scrollbar color in store locator when user set high contrast theme
        &__locations::-webkit-scrollbar-thumb {
            background-color: $msv-black;
            outline: 1px solid $msv-black;
            border-radius: 10px;
        }
    }

    &__location {
        padding: $msv-store-selector-location-padding;
        border-radius: 0px;
        margin-bottom: $msv-store-selector-location-margin-bottom;
        position: relative;
        border-top: 1px solid $msv-gray-50;

        &.selected {
            background-color: var(--msv-store-select-background-color);
        }

        &-wrapper {
            margin-bottom: $msv-store-selector-wrapper-margin-bottom;
            padding-bottom: $msv-store-selector-wrapper-padding-bottom;
            border: 0.5px solid $msv-gray-50;
            border-radius: 10px;
            display: flex;
            flex-direction: column;

            @media (max-width: $msv-breakpoint-m) {
                margin-bottom: $msv-store-selector-wrapper-mobile-margin-bottom;
                padding-bottom: $msv-store-selector-wrapper-mobile-padding-bottom;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            padding: $msv-store-selector-location-mobile-padding;
        }

        // @include add-icon($msv-store, before);

        // &::after {
        //     width: 40px;
        //     height: 40px;
        //     content: "";
        //     position: absolute;
        //     left: 0;
        //     border-radius: 0 8px 8px 0;
        //     border: 0.5px solid $msv-gray-50;
        //     border-left: none;
        //     top: 15px;
        // }

        // &::before {
        //     position: absolute;
        //     top: 27px;
        //     left: 10px;
        //     color: $msv-gray-20;
        //     z-index: 1;
        // }

        &:last-child {
            margin-bottom: 0;
            border-bottom: 1px solid $msv-gray-50;
        }

        &-line-item {
            color: var(--msv-store-select-text-font-color);
            font-size: var(--msv-store-select-text-font-size);
            position: relative;
            height: 100%;

            &-set-as-preferred-store {
                // border: none;
                // color: var(--msv-store-select-link-color);
                // padding: $msv-store-selector-set-preferred-store-padding;
                // outline: none;
                // background-color: transparent;
                // cursor: pointer;
                // display: flex;
                // align-items: center;
                // position: absolute;
                // top: -16px;
                // right: 0;
                // overflow: hidden;
                // text-align: left;
                // width: 220px;

                // @include font-body-regular-s();

                // @media screen and (max-width: $msv-breakpoint-m - 1) {
                //     position: relative;
                //     border-top: 1px solid var(--msv-store-select-divider-color);
                //     width: 100%;
                //     padding: $msv-store-selector-set-preferred-store-mobile-padding;
                //     margin-top: $msv-store-selector-set-preferred-store-mobile-margin-top;
                //     justify-content: center;
                //     flex-direction: row-reverse;
                //     left: auto;
                //     top: 0;
                //     right: auto;
                // }

                // &:hover {
                //     text-decoration: none;
                // }

                // &:focus {
                //     border: 1px solid $msv-blue;
                //     border-radius: 100px;
                // }

                // &::after {
                //     width: 24px;
                //     height: 24px;
                //     display: inline-block;
                //     border: 0.5px solid $msv-gray-50;
                //     border-radius: 3px;
                //     box-sizing: border-box;
                //     padding: 2px;
                //     content: "";
                //     position: absolute;
                //     left: 16px;
                //     top: 14px;

                //     @media screen and (max-width: $msv-breakpoint-m - 1) {
                //         left: auto;
                //         position: relative;
                //         margin-right: 10px;
                //         top: 0;
                //     }
                // }

                @include secondary-button-dark();

                @media screen and (max-width: $msv-breakpoint-m - 1) {
                    margin-top: 12px;
                }
            }

            &-preferred-store {
                display: block;
                margin-top: 0;
                position: absolute;
                top: -16px;
                right: 0;
                padding: $msv-store-selector-line-item-preferred-store-padding;
                align-items: center;
                overflow: hidden;
                text-align: left;
                width: 220px;

                @include font-body-regular-s();
                cursor: pointer;
                border: 1px solid transparent;
                outline: none;
                background-color: transparent;

                @media screen and (max-width: $msv-breakpoint-m - 1) {
                    position: relative;
                    border-top: 1px solid var(--msv-store-select-divider-color);
                    width: 100%;
                    padding: $msv-store-selector-line-item-preferred-store-mobile-padding;
                    margin-top: $msv-store-selector-line-item-preferred-store-mobile-margin-top;
                    justify-content: center;
                    flex-direction: row-reverse;
                    display: flex;
                    right: auto;
                    top: 0;
                }

                &:hover {
                    text-decoration: none;
                }

                &:focus {
                    border: 1px solid $msv-blue;
                    border-radius: 100px;
                }

                &::before {
                    position: absolute;
                    left: 23px;
                    top: 15px;
                    border: solid $msv-black;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    width: 10px;
                    height: 18px;
                    content: "";

                    @media screen and (max-width: $msv-breakpoint-m - 1) {
                        left: -125px;
                        top: -2px;
                        position: relative;
                    }
                }

                &::after {
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    border: 0.5px solid $msv-gray-50;
                    border-radius: 3px;
                    box-sizing: border-box;
                    padding: 2px;
                    content: "";
                    position: absolute;
                    left: 16px;
                    top: 14px;

                    @media screen and (max-width: $msv-breakpoint-m - 1) {
                        left: auto;
                        top: auto;
                        position: relative;
                        margin-right: 10px;
                    }
                }
            }
        }

        &-line-header {
            margin-bottom: $msv-store-selector-location-line-header-margin-bottom;

            @include font-body-light-s();
            font-size: 14px;
        }

        &-line-stock-status {
            margin-bottom: $msv-store-selector-location-line-stock-status-margin-bottom;

            @include font-body-regular-s();
        }

        &-line-item-header {
            margin-bottom: $msv-store-selector-location-line-item-header-margin-bottom;
            padding-left: $msv-store-selector-location-line-item-header-padding-left;

            @media screen and (max-width: $msv-breakpoint-m) {
                margin-bottom: $msv-store-selector-location-line-item-header-mobile-margin-bottom;
                padding: 0;
            }
        }

        &-line-item-store-name {
            color: var(--msv-store-select-title-font-color);
            @include font-heading-h6-l();
            font-family: 'Conv_EuropaLight';
        }

        &-line-item-store-index {
            color: var(--msv-store-select-title-font-color);

            @include font-heading-h6-l();
            margin-right: 5px;
            display: none;

            &::after {
                content: ".";
            }
        }

        &-line-item-store-distance {
            display: none;
            @include font-body-regular-xs();
            color: var(--msv-store-select-distance-color);
            color: $msv-black-10;
            padding-left: 12px;
        }

        &-line-store-distance {
            color: $msv-gray-500;
        }

        &-line-item-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &-line-shop-address {
            &-section {
                display: flex;
            }

            &-glyph-icon {
                padding-top: $msv-store-selector-location-line-shop-address-padding-top;
                padding-right: $msv-store-selector-location-line-shop-address-padding-right;

                @include add-icon($msv-Shop-Address);
            }

            &-text {
                @include font-body-light-s();
                font-size: 14px;
                white-space: pre-wrap;
            }
        }

        &-line-shop-phone {
            &-section {
                display: flex;
                padding-top: $msv-store-selector-phone-section-padding-top;
            }

            &-icon {
                padding-top: $msv-store-selector-location-line-shop-address-padding-top;
                padding-right: $msv-store-selector-location-line-shop-address-padding-right;

                @include add-icon($msv-Phone, before);
            }

            &-text {
                @include font-body-regular-s();
            }
        }

        &-line-select-store {
            @include primary-button-dark();
            align-self: flex-end;
            margin-right: 20px;

            &-btn {
                background-color: var(--msv-primary-btn-dark-bg-color);
                font-size: 14px;
                color: $msv-white;
                text-transform: uppercase;
                border: none;
            }
        }

        &-line-pickup-button-error {
            padding: 0 20px;
            display: none;
            align-items: center;
            margin-top: 12px;

            @include font-body-regular-xs();
            @include add-icon($msv-times-circle, before);
            color: var(--ms-pickup-option-error-color);

            &::before {
                margin-right: 10px;
                font-size: 20px;
            }

            &[data-pickup-error="true"] {
                display: flex;
            }
        }

        &-line-pickup {
            z-index: 1000;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            &-menu {
                @include primary-button-light();

                cursor: pointer;
                align-self: flex-start;
                margin-left: auto;
            }

            &-menu:active:focus:hover {
                border: none;
                outline: none;
            }

            &-label {
                @include font-body-bold-s();
                flex: 0 0 100%;
                margin-bottom: 7px;
            }

            &-list-toggle {
                text-align: left;

                @include form-input-box();
                @include add-icon($msv-ChevronDown, after);
                padding-left: $msv-store-selector-location-line-pickup-list-toggle-padding-left;

                &::after {
                    float: right;
                    right: 225px;
                    font-size: 20px;

                    @media (max-width: $msv-breakpoint-m) {
                        right: 18px;
                    }

                    @media (min-width: $msv-breakpoint-m + 1) and (max-width: $msv-breakpoint-l) {
                        right: 255px;
                    }
                }

                &:focus {
                    outline: none;

                    &::after {
                        right: 11px;
                    }
                }
            }

            &[data-selected="true"] {
                .ms-store-select__location-line-pickup-label {
                    @include add-icon($msv-Checkbox-Circle-Checked, after);

                    &::after {
                        position: relative;
                        top: 48px;
                        z-index: 10;
                        left: -85px;
                        font-size: 20px;
                        color: var(--ms-pickup-option-icon-color);
                    }
                }

                .ms-store-select__location-line-pickup-list-toggle {
                    padding-left: 45px;

                    &.preselected {
                        &::after {
                            content: none;
                        }
                    }
                }
            }

            &-list {
                display: none;

                &.show {
                    display: block;
                    border: 0.5px solid $msv-gray-50;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                &-container {
                    display: flex;
                    flex-direction: column;
                    width: 55%;

                    @media (min-width: $msv-breakpoint-m + 1) and (max-width: $msv-breakpoint-l) {
                        width: 45%;
                    }

                    @media (max-width: $msv-breakpoint-m - 1) {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }

                @include font-body-regular-s();

                &-item {
                    background-color: var(--msv-store-select-form-bg);

                    &-button {
                        border: none;
                        padding-left: $pickup-option-list-item-padding-left;
                        background-color: var(--msv-store-select-background-color);
                        width: 100%;
                        text-align: left;
                        height: $pickup-option-height;
                    }

                    &:hover {
                        background-color: $msv-white-20;
                        cursor: pointer;
                    }

                    &__text {
                        width: 100%;

                        &:hover::before {
                            outline: none;
                        }
                    }
                }

                &-link {
                    border: none;
                    line-height: $msv-store-location-pickup-option-line-height;
                    padding-bottom: $pickup-option-list-item-padding-bottom;
                    padding-left: $pickup-option-list-item-padding-left;
                    padding-top: $pickup-option-list-item-padding-top;
                    padding-right: $pickup-option-list-item-padding-right;
                    text-align: center;
                    width: 100%;
                    outline: none;

                    @include font-body-regular-s();
                }

                &-error {
                    display: none;

                    @include font-body-regular-xs();
                }
            }

            &[data-error="true"] {
                .ms-store-select__location-line-pickup-list-error {
                    display: flex;
                    align-items: center;
                    margin-top: 12px;
                    color: var(--ms-pickup-option-error-color);

                    @include add-icon($msv-times-circle, before);

                    &::before {
                        margin-right: 10px;
                        font-size: 20px;
                    }
                }
            }
        }
    }

    &__terms-link {
        @include font-body-regular-s();
        color: var(--msv-store-select-link-color);
        text-decoration: underline;
        border: none;
    }

    &.msc-modal__dialog select {
        /* stylelint-disable-next-line declaration-no-important -- To override important styling for modal select element. */
        background-color: transparent !important;
    }
}

@media screen and (min-width: $msv-breakpoint-m) {
    .ms-store-select {
        max-height: 100vh;
        overflow-y: visible;
    }

    .ms-store-select__map {
        max-width: 1096px;
    }

    .ms-store-select__location {
        &-line-item-content {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &-line-item-contact-info {
            width: 50%;
            margin-right: 15px;
        }

        &-line-item-store-hours {
            width: 38%;
            margin-right: 15px;
            flex: auto;
        }

        &-line-item-store-availability {
            width: 21%;
            text-align: right;
        }

        &-line-stock-status {
            @include font-body-regular-s();
            position: relative;
        }
    }

    .ms-store-select__map__selected_store__main {
        padding: 20px 56px 10px 56px;
        border-radius: 10px;
        margin-bottom: 10px;
        position: relative;
        overflow: scroll;
        height: 100%;
        max-height: 83%;
        background-color: var(--msv-store-select-background-color);

        // @include store-card-icon();

        &::after {
            top: 15px;
            left: 0;
        }

        &::before {
            top: 27px;
            left: 10px;
        }

        .ms-store-select__map__selected_store__header {
            margin-bottom: 13px;
            padding: 0;

            .ms-store-select__map__selected_store__header_store_name {
                font-weight: bold;
            }

            .ms-store-select__map__selected_store__header_store-distance {
                display: none;
                @include font-body-regular-xs();
                color: var(--msv-store-select-distance-color);
                padding-left: 12px;
            }

            .ms-store-select__map__selected_store__header_close_button {
                @include close-button();
                right: 20px;
            }
        }

        .ms-store-select__map__selected_store__container {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            display: flex;
            padding-top: 10px;
        }

        .ms-store-select__location-line-item-set-as-preferred-store, .ms-store-select__location-line-item-preferred-store {
            top: 5px;
            right: 100px;
        }

        .ms-store-select__location-line-select-store {
            float: right;
            margin: 0;
            margin-top: 40px;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-l - 1) {
    .ms-store-select {
        &__toggle-view {
            @include font-body-regular-s();
            text-decoration: underline;
            display: block;
            background-color: transparent;
            float: right;
            outline: none;
            border: none;
            margin-left: 12px;
            margin-top: 12px
        }

        &__toggle-view.view-map {
            @include add-icon($msv-Shop-Address, after);

            &::after {
                font-size: 20px;
                vertical-align: bottom;
                padding-left: $msv-store-location-pickup-option-filter-padding;
            }
        }

        &__toggle-view.list-view {
            @include add-icon($msv-list-line, after);

            &::after {
                font-size: 20px;
                vertical-align: bottom;
                padding-left: $msv-store-location-pickup-option-filter-padding;
            }
        }

        &.ms-store-select__map {
            .ms-store-select__map_view.hide {
                display: none;
            }

            .ms-store-select__map__store_details_wrapper.show, .ms-store-select__map__selected_store__modal.show {
                .ms-store-select__location-line-item-store-availability {
                    .ms-store-select__location-line-select-store {
                        padding: 12px 48px;
                        margin-right: 0;
                        position: absolute;
                        bottom: 0;
                        right: 0;

                        @media screen and (max-width: $msv-breakpoint-m - 1) {
                            position: relative;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-store-select {
        &__location {
            padding: 20px 56px 20px 56px;

            &-line-select-store {
                margin-right: 56px;
            }

            &-line-pickup {
                padding: 0;
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-m - 1) {
        .ms-store-select {
            &__location {
                padding: 20px 0 0 0;
                margin-bottom: 20px;

                &-line-select-store {
                    width: 100%;
                    margin: 0;
                    max-width: 100%;
                    align-self: flex-start;
                }

                &-line-header {
                    margin-bottom: 0;
                }

                &-line-item {
                    &-header {
                        padding-left: 0px;
                        margin-bottom: 0px;
                    }

                    &-store-hours,
                    &-store-availability,
                    &-contact-info {
                        margin-top: 13px;
                        padding-left: 0px;
                    }

                    &-store-availability {
                        padding-bottom: 12px;
                    }
                }

                &-line-pickup {
                    flex-direction: column;
                    align-items: stretch;
                    padding-top: 9px;

                    &-list {
                        margin-bottom: 13px;
                        width: 100%;
                    }

                    &-menu {
                        margin-left: 0;
                        width: 100%;
                    }
                }
            }
        }

        .ms-store-select__map__selected_store__main {
            overflow: scroll;
            margin: 0;
            height: 100%;
            max-height: 85%;
            padding: 20px 0 0 0;
            background-color: var(--msv-store-select-background-color);

            // @include store-card-icon();

            &::after {
                top: 15px;
                left: 10px;
            }

            &::before {
                top: 27px;
                left: 20px;
            }

            .ms-store-select__location-line-pickup {
                padding: 10px 56px;
            }

            .ms-store-select__location-line-select-store {
                display: flex;
            }

            .ms-store-select__map__selected_store__header {
                margin-bottom: 13px;
                padding: 0;
                padding-left: 56px;

                .ms-store-select__map__selected_store__header_store_name {
                    font-weight: bold;
                }

                .ms-store-select__map__selected_store__header_store-distance {
                    @include font-body-regular-xs();
                    color: var(--msv-store-select-distance-color);
                    padding-left: 12px;
                }

                .ms-store-select__map__selected_store__header_close_button {
                    @include close-button();
                    right: 20px;
                }
            }
        }
    }

    .ms-store-select__location-wrapper {
        .ms-store-select {
            &__location {
                &-line-pickup {
                    width: 100%;
                    margin: 0 auto;
                    max-width: 90%;
                }
            }
        }
    }
}

@media screen and (max-width: $msv-breakpoint-l - 1) {
    .ms-store-select {
        &__locations {
            max-height: calc(100vh - 150px);
        }

        &__map {
            height: 100%;

            .msc-modal__content {
                height: 100%;
                overflow-y: auto;

                .ms-store-select__modal-body {
                    max-height: 100%;
                    height: 100%;
                }
            }

            .ms-store-select {
                &__locations {
                    display: none;

                    &.show {
                        display: block;
                    }
                }

                &__search-header {
                    width: 100%;
                    justify-content: end;
                    padding-top: 8px;
                }

                &__store_container {
                    width: 100%;

                    .ms-map {
                        height: 100vh;
                        display: none;
                    }

                    .ms-map.show {
                        display: block;
                    }
                }

                &__map_container {
                    width: 100%;
                }
            }

            &__store_details_wrapper, &__selected_store__modal {
                bottom: 0;
                display: inline-block;
                height: 410px;
                position: absolute;
                transition: max-height 0.5s linear;
                width: 100%;
                z-index: 100;
                box-shadow: $msv-depth8;
                padding: 0 10px;

                &.hide {
                    max-height: 0;
                    overflow: hidden;
                }

                &.show {
                    opacity: 1;
                    max-height: 350px;
                    z-index: 1002;

                    .ms-store-select__locations {
                        padding: 0;
                        margin: 0;
                        overflow-y: auto;
                        height: 100%;
                    }

                    .ms-store-select__location.selected {
                        overflow-y: scroll;
                        max-height: 350px;
                        height: 100%;

                        .ms-store-select__location-line-pickup {
                            padding: 0;
                            position: absolute;
                            bottom: 15px;
                            right: 0;

                            @media screen and (max-width: $msv-breakpoint-m - 1) {
                                position: relative;
                                right: 20px;
                                bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-modal-m) {
        .ms-store-select {
            &__locations {
                max-height: calc(100vh - 200px);
            }
        }

        .ms-store-select__search-header-heading {
            display: none;
        }

        .ms-store-select__search-see-all-stores {
            display: none;
            padding: 16px 0;
            margin: 0;
        }

        .ms-store-select__search-header {
            padding-left: 0;
            /* stylelint-disable-next-line declaration-no-important -- To override important styling for modal select element. */
            padding-top: 36px !important;
            /* stylelint-disable-next-line declaration-no-important -- To override important styling for modal select element. */
            justify-content: space-between !important;
        }

        .ms-store-select__map__store_details_wrapper, .ms-store-select__map__selected_store__modal {
            height: 395px;

            &.show {
                max-height: 395px;
            }
        }
    }
}

@media screen and (min-width: $msv-breakpoint-m + 1) {
    .ms-store-select__location {
        &-line-item-content {
            padding: 0;
        }
    }
}

// ------- MAP IFRAME MANIPULATION -------

iframe[style="position: fixed; top: 200px; left: 0px; width: 100%; height: 100%; border: medium; z-index: 2147483647;"] {
    top: 200px !important;
    border: 1px solid red !important;
}
